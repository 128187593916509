import React from "react"

import Layout from "../components/layout/index"
import SEO from "../components/seo"

import Content from '../templates/quote/index'

export default () => {
    return (
        <Layout>
            <SEO title="Request a Quote" />
            <Content/>
        </Layout>
    )
}
