import React from 'react'
import styled from 'styled-components'

// Components
import Container from '../../components/container'
import Form from './form/index'
import Header from './header'

export default () => {
    return (
        <React.Fragment>
            <Header 
                title={`Get a Free Estimate`}
                description={`Reliable Iowa Construction can handle all of your design needs.`}
            />
            <Section>
                <Container
                    sm={`
                        padding-bottom:3rem;
                        display:grid;
                        grid-row-gap:1.5rem;
                        z-index:2;
                    `}
                    lg={`
                        grid-template-columns: 65%;
                        justify-content: center;
                        grid-column-gap:4.5rem;
                        padding-bottom:4.5rem;
                    `}
                >
                    <Form/>
                </Container>
            </Section>
        </React.Fragment>
    )
}

// Styled Components
const Section = styled.section`
    background-color:rgb(245,245,250);
    overflow:visible;
    position:static;
`