import React from 'react'
import styled from 'styled-components'

// Assets
import BackgroundImage from '../../images/background/wave.svg'

// Components
import Container from '../../components/container'

export default ({
    title,
    description
}) => {
    return (
        <Header>
            <Background />
            <Container
                sm={`
                    padding-top:3rem;
                    padding-bottom:6rem;
                `}
                lg={`
                    padding-top:4.5rem;
                    padding-bottom:9rem;
                    max-width:800px;
                `}
            >
                <Title>{title}</Title>
                <Description>{description}</Description>
            </Container>
        </Header>
    )
}

// Styled Components
const Header = styled.header`
    text-align:center;
    position:relative;
    box-shadow: 
        inset 0px 2.5rem 1rem -1.5rem rgb(255,255,255),
        inset 0px -2.5rem 1rem -1.5rem rgb(245,245,250)
    ;
    @media (min-width:992px) {
        box-shadow: 
            inset 0px 6rem 1rem -4.5rem rgb(255,255,255),
            inset 0px -6rem 1rem -4.5rem rgb(245,245,250)
        ;
    }
`
const Background = styled.div`
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-image:url(${BackgroundImage}), linear-gradient(rgb(255, 255, 255), rgb(245, 245, 250));
    background-size:contain;
    background-repeat:no-repeat;
    text-align:center;
    z-index:-1;
    opacity:0.5;
    background-size: 100% auto;
    background-position-y:center;
    @media (min-width:992px) {
        opacity:0.75;
        background-position-y:top;
        background-position-y:calc(50% + 3rem);
    }
`
const Title = styled.h1`
    color: ${props => props.theme.color.blue.dark};
    font-size:1.5rem;
    @media (min-width:992px) {
        font-size:3rem;
        text-transform:uppercase;
    }
`
const Description = styled.p`
    font-size:1rem;
    padding:0.5rem 0 0 0;
    @media (min-width:992px) {
        padding:0.5rem 0 0;
        font-size:1.25rem;
    }
`